.circle{
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background-color: deepskyblue;
    position: absolute;
    opacity: 0;

    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}

@keyframes scaleIn {
    from {
        transform: scale(.5, .5);
        opacity: .5;
    }
    to {
        transform: scale(2.5, 2.5);
        opacity: 0;
    }
}

.image_centrale{
    z-index: 100;
    padding: 5px;
}
.image_centrale{
    width: 100px;
}