.FaqQuestion.active {
    background-color: #eaeaea;
    color: #333333;
}
  
.FaqAnswer.active {
    display: block;
    margin-top: 10px;
}

.footer_accueilmenu{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 10vh;
    background: #eff3ef;
    color: white;
}

/*#footer{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
}*/

.footer_scrollermenu{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    
    width: 100%;
    display: flex;
    /*justify-content: space-around;*/
    align-items: center;
    min-height: 10vh;
    /*background: #8caa8d;*/
    color: white;
    overflow-x: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.footer_scrollermenu::-webkit-scrollbar{
    display: none;
}