body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*STYLING THE CREATED MODAL DOM ELEMENT*/
.modal{
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  /*width: 30rem;*/
  max-width: 80%;
  margin-left: 5%;
  z-index: 10;
  position: fixed;
  top: 30vh;
  /*left: calc(50% - 15rem);*/
}

.btn {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #800040;
  color: white;
  border: 1px solid #800040;
  margin: 0 1rem;
}

.btn--heure {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #306d1b;
  color: white;
  border: 1px solid #306d1b;
  margin: 0 1rem;
}

.btn:hover {
  background-color: #9c1458;
  border-color: #9c1458;
}

.btn--alt {
  background-color: transparent;
  color: #800040;
}

.btn--alt:hover {
  background-color: #f8dae9;
}

.btn--heure:hover {
  background-color: #f8dae9;
}

.backdrop {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.modalalert{
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  /*width: 30rem;*/
  max-width: 80%;
  margin-left: 5%;
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*top: 30vh;*/
  /*left: calc(50% - 15rem);*/
}

.btnalert {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #800040;
  color: white;
  border: 1px solid #800040;
  margin: 0 1rem;
}

.btnalert:hover {
  background-color: #9c1458;
  border-color: #9c1458;
}

.btnalert--alt {
  background-color: transparent;
  color: #800040;
}

.btnalert--alt:hover {
  background-color: #f8dae9;
}

.backdropalert {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

/*Css all tables*/
/*.thin_table_config th {
  background-color: #999999;
  color: #ffffff;
  font-size: 17px;
}
.thin_table_config tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.26);
}
.thin_table_config td:first-child {
  width: 10%;
}
.thin_table_config td:nth-child(2) {
  width: 20%;
}
.thin_table_config th,
.thin_table_config td {
    border: 1px solid #656150;
    padding: 0.3em;
    font-size: 17px;
}
.thin_table_config {
  background-color: #999999;
  width: 98%;
}*/
