.accueilmenu{
    top: 210px;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-height: 120px;
    /*background: #8caa8d;*/
    color: white;
    overflow-x: scroll;
}

.scrollermenu{
    width: 99%;
    display: flex;
    /*justify-content: space-around;*/
    align-items: center;
    max-height: 100px;
    /*background: #8caa8d;*/
    color: white;
    /*overflow-x: scroll;*/
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollermenu::-webkit-scrollbar{
    display: block;
}